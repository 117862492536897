import { PageProps } from 'gatsby';
import { UserData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../components/List/Item/User';
import List from '../../containers/Espace/List';
import userParams from '../../params/user/index.json';
import TemplateAdmin from '../../templates/admin';
import requireUser, { UserProps } from '../../utils/requireUser';

const AdminUsers: FC<PageProps & UserProps> = ({ location, user }) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <List
        itemList={Item}
        model={new UserData({ params: userParams })}
        search={location.search}
        title={t('users.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(AdminUsers, 'admin');
